import { Box, Button, Card, CardContent, Container, Divider, Grid, Paper, Stack, Typography, styled, useMediaQuery } from '@mui/material'
import { useStyles } from '@styles'
import mylioLogo from '@assets/Mylio Photos+.png'
import imgOne from '@assets/Image_001.jpg'
import imgTwo from '@assets/Image_002.jpg'
import imgThree from '@assets/Image_003.jpg'
import imgFour from '@assets/Image_004.jpg'
import imgFive from '@assets/Image_005.jpg'
import imgSix from '@assets/Image_006.jpg'
import imgSeven from '@assets/Image_007.jpg'
import imgEight from '@assets/Image_008.jpg'
import imgNine from '@assets/Image_009.jpg'
import imgTen from '@assets/Image_010.jpg'
import imgEleven from '@assets/Image_011.jpg'
import imgTwelve from '@assets/Image_012.jpg'
import bannerImage from '@assets/banner.png'
import { useEffect } from 'react';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const News = () => {

  const isBig = useMediaQuery(`(min-width:700px)`)
  const classes = useStyles()

  useEffect(() => {
    document.body.classList.add('news-layout')
    return () => {
      document.body.classList.remove('news-layout')
    }
  }, [])


  const profileCard = (
    <>
      <CardContent sx={{ backgroundColor: "#f5f5f5", width: isBig ? "300px" : "67vw", padding: "20px", borderRadius: "10px", justifyContent: "center", textAlign: "center" }}>
        <img
          src={imgOne}
          alt="placeholder image"
        />

        <Typography variant="h5" component="div" color="#000" fontWeight={'600'} sx={{ mb: 2, mt: 1.5 }}>Concierge</Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} className='tag-container'>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Small Bussinesses</Typography>
          <Typography sx={{ mb: 1.5, backgroundColor:'#F9FAFD' }} className={classes.tags} fontSize={'13px'}>Hobbyist</Typography>
        </Stack>

        <Typography variant="body2" color="#000">
          For those who don’t have time to do it themselves or seek mentorship on their journey.
        </Typography>
      </CardContent>
  
    </>
  );
  
  const serviceDescription = (
    <>
      <CardContent>
        {/* <Typography sx={{ fontSize: isBig? '34px' : '21px', fontWeight: 'bold' }} color="#000" gutterBottom>
          One-on-One Services
        </Typography> */}
        <Typography variant="h5" component="div" color="#000"  sx={{ fontSize: '16px', mt:3.5, mb:3 }}>
          Three one-hour sessions | $280 <span style={{textDecoration: 'line-through'}}>$450</span>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="#000">
          We have a network of Photo Managers with Varying specialties such as:
        </Typography>
        <Stack direction='row' spacing={3} color="#000" className='tag-container' sx={{ display: 'inline' }}>
          <Typography className={classes.tags}>Cataloging scanned media</Typography>
          <Typography  className={classes.tags}>Mixed media libraries</Typography>
          <Typography  className={classes.tags}>Customized syncing</Typography>
          <Typography  className={classes.tags}>Shared accounts for work or family</Typography>
          <Typography  className={classes.tags}>Multi-layered backups</Typography>
          <Typography  className={classes.tags}>Small businesses</Typography>
        </Stack>
      </CardContent>
  
    </>
  );


 return (
  
  <>
  <Stack sx={{ height:'100vh', py: isBig ? `1vh` : `3vh`, paddingLeft: isBig? `0`:`92px`, paddingRight: isBig? `0`:`74px`, overflow: `auto`}} >


    <Stack direction="column" justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw`, margin: '0 auto' }}>
      <img
        src={imgOne}
        alt="placeholder image"
        width={'100%'}
        style={{ margin: '0 auto', marginTop:'20px', marginBottom: '20px' }}
      />
      <Typography sx={{ mb: 5, mt: 3.5, fontSize: isBig ? '48px !important' : '33px !important', fontWeight: 'bold' }} color="#000" variant='h3'>
        What's new in Mylio Photos 24.4
      </Typography>
      <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
        We're excited to announce a significant update to Mylio Photos. <br/><br/>
      </Typography>

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'} sx={{ paddingBottom: '10px' }}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
          
          <img
            src={imgTwo}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', paddingTop: '0px' }} color="#000" variant='h5'>
            Keyword Improvements
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Keywords are a powerful tool for staying organized in Mylio Photos. Use new <b>Keyword QuickFilters</b> to narrow a 
            view to just matching results. Keywords can also be used in <b>Dynamic Search</b>, providing an additional method to 
            quickly locate photos you've meticulously organized using keywords.
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important' }} href="https://mylio.link/06020">Learn More</Button>
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgThree}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
            Mylio Drive setup improvements
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Every Mylio Photos+ plan includes unlimited Mylio Drive storage for Optimized Images at no extra cost, giving you easy, private access to your entire photo library across devices. To set it up, just click the new dedicated button at the top of the Devices tab in the Dashboard.
            {/* <ul>
              <li>On mobile/tablet – Click the More menu in the upper right corner (…) and choose Find.</li>
              <li>On a computer – Press Cmd+F (macOS) or Ctrl+F (Windows).</li>
            </ul> */}
          
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06021">Learn More</Button>
        </Stack>



      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />


      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px'}}>
        <Stack >
          <img
            src={imgFour}
            alt="placeholder image"
            width={'100%'}
          />
        </Stack>
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', marginTop: '20px'}} color="#000" variant='h5'>
            Mylio Drive+ and SecureCloud
          </Typography>
          <Typography sx={{ mb: 2, mt: '0px !important'  }} color="#000" variant='body1'>
            Upgrade your Mylio Drive experience with a SecureCloud subscription to access Mylio Drive+.
            <ul>
              <li><b>Broad File Support:</b> - Unlike the standard Mylio Drive, Mylio Drive+ lets you store photos, videos, and documents within your Mylio Photos library.</li>
              <li><b>Powered by Backblaze:</b> - Mylio Drive+ is backed by Backblaze, a highly reliable and secure hosting network.</li>
              <li><b>Encryption and Security:</b> - All files are encrypted, ensuring only your signed-in devices can access them, keeping your data private and secure.</li>
              <li><b>Precision Syncing:</b> - Choose which files to store in the cloud based on ratings, labels, folders, and more, giving you full control.</li>
              <li><b>File Integrity:</b> - Files are verified for accuracy, and only authorized devices can access SecureCloud storage, safeguarding your files.</li>
            </ul>

            Note: Mylio Drive+ is completely optional. If you prefer to stay cloud-free, your current setup remains unchanged. Similarly, if you already have a cloud storage plan connected to Mylio Photos, there's no need to make any changes.
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06022">Learn More</Button>
        </Stack>

        

      </Stack>


      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

      
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgFive}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
            QuickFilter Panel Improvements
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            <ul>
              <li>We've added Solo Mode to reduce scrolling in the QuickFilter tree. With <b>Solo Mode</b> enabled, only one parent group remains open at a time. Toggle this and other QuickFilter visibility options via the Gear icon.</li>
              <li>Press \ to quickly close all groups, and use arrow keys and Return for <b>keyboard navigation</b>.</li>
              <li>The "By Filetype" filters now support OR logic, allowing you to stack filters like Photos and Documents.</li>
            </ul>
          
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06023">Learn More</Button>
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />


      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '20px' : '20px'  }}>
          <img
            src={imgSix}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px'}} color="#000" variant='h5'>
            Dynamic Search Improvements
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>

            <ul>
              <li>Enhancements include <b>recent searches </b>(up to 20) and <b>autocomplete suggestions</b>.</li>
              <li>You can now disable <b>SmartTags</b> in search results through the More menu. </li>
              <li>Use the <b>Storage Panel</b> to inspect item details.</li>
              <li>You can also use keyboard navigation to move through recent searches and autocomplete suggestions. Use the arrow keys to navigate and the Return key to select items.</li>
            </ul>

          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06024">Learn More</Button>
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgSeven}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px' }} color="#000" variant='h5'>
            Find Improvements
          </Typography>
          <Typography sx={{ mb: 5, mt: '2px !important'  }} color="#000" variant='body1'>
            The Find dialog now works across all views, including All Photos, Life Calendar, Map, People, Albums, and Folders.
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>

            <ul>
              <li>Press Cmd+F (Mac) or Ctrl+F (Windows) to open it. </li>
              <li>When multiple results match, you can see a match count and select all results with Cmd+A or Ctrl+A for further actions (or click the underlined number).</li>
              <li>Once selected, you can use the <b>Quick Actions</b> panel or <b>Info</b> panel to share, move, or bulk modify the selected items.</li>
            </ul>

          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06025">Learn More</Button>
        </Stack>

        


      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px' }}>
          <img
            src={imgEight}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '0px', marginTop: '10px'  }} color="#000" variant='h5'>
            Assign Categories During Import
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          You can now assign categories directly when importing files using Copy, Move, or Link. 
          Categories like Personal, Family, or Work stay with the media, even if moved later, 
          keeping your library organized from the start.
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06026">Learn More</Button>
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      <Stack direction={{ xs: 'column' }} textAlign={'left'} sx={{ paddingBottom: '10px'}}>

          <img
            src={imgNine}
            alt="placeholder image"
            width={'100%'}
          />
       
        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', paddingTop: '20px' }} color="#000" variant='h5'>
            Precision Syncing with SyncCollections
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          For most users, the Device Sync Policy Presets offer a clear way to setup a device.  Options such as SpaceSaver, Catalog Only, or Vault cover the most common use case scenario.  
          However, if you’d like more granular control over the files stored on a specific device, you can use <b>SyncCollections</b>. These are groups of files that you want to sync at Optimized and/or Original quality.
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            <b>SyncCollections can be configured in many ways. Here are a few examples:</b>
            <ul>
              <li>Keep all 5-star photos of the people in your family on your phone as optimized quality Images so you always have a high-quality version to view and share.</li>
              <li>Back up your media marked with 4-Stars, 5-Stars, and Pick Flags at the original quality on Mylio Drive+.</li>
              <li>Keep all raw photos from a specific camera captured within the last 90 days on your laptop so you can edit and work with them, even offline.</li>
            </ul>

          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06027">Learn More</Button>
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0'}} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: '10px', paddingTop: isBig ? '0px' : '40px'  }}>
          <img
            src={imgTen}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', paddingTop: '0px' }} color="#000" variant='h5'>
            Updated Folder Appearance
          </Typography>
          <Typography color="#000" sx={{ marginTop: '0px' }}>
          Working with Folders is one of the key benefits of Mylio Photos.  It makes it truly easy to precisely control the contents of your drives and devices.  With Mylio Photos+ you can even manage the folders of all your devices from any location you are logged in.
            
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06028">Learn More</Button>
          {/* <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', marginTop: '30px !important', margin: '0 auto !important'  }} href="https://manual.mylio.com/topic/post-to-viewbug">Learn More</Button> */}
        </Stack>

      </Stack>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '40px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />

      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingTop: '30px' }}>
          <img
            src={imgEleven}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '20px', paddingTop: '20px' }} color="#000" variant='h5'>
            frame.io Integration with Lightroom
          </Typography>
          <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
          If you are using frame.io and Camera to Cloud you'll see improved options in the ecosystem.  Remember Mylio Photos supports both importing from <a target="_blank" href="https://frame.io/">frame.io</a> as well as publishing to the service.  We also have a frame.io Project Watcher that recently shipped.
          <br /> <br/> Now the <a target="_blank" href='https://blog.frame.io/2024/08/19/camera-to-cloud-integration-with-adobe-lightroom/'>Lightroom team</a> at Adobe has also added support for an Adobe Lightroom option to connect to frame.io.  We are glad to support this collaboration tool and that Mylio Photos users have improve workflow options for applications like Premiere Pro, After Effects, and Lightroom (with more options expected on the way).
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px', margin: '0 auto !important', marginTop: '30px !important'  }} href="https://mylio.link/06029">Learn More</Button>
        </Stack>

      </Stack>
      <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '20px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />
      <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

        <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px', paddingBottom: isBig ? '10px' : '20px', paddingTop: isBig ? '50px' : '50px' }}>
          <img
            src={imgTwelve}
            alt="placeholder image"
            width={'100%'}
          />
          <Typography sx={{ fontWeight: '700', marginBottom: '10px', paddingTop: '0px' }} color="#000" variant='h5'>
          Other usability improvements 
          </Typography>
          <Typography color="#000">
          Here are some additional updates to Mylio Photos that we think you’ll enjoy:
          <ul>
              <li><b>Per-Image Sync Policy:</b> Adjust Sync Policies for individual images in the Info panel. Your selection is added to the syncing rules and stays in effect unless changed.</li>
              <li><b>Custom Spaces & QuickCollections:</b> When creating a Space, use Category-based QuickCollections to define viewable files. The menu now clearly shows supported options.</li>
              <li><b>Spaces Switcher Access:</b> The Spaces switcher is now in the View menu for easier access on Desktop and Laptop devices.</li>
              <li><b>Batch Tagging:</b> Batch Tagging for people now respects active Space settings for consistent tagging.</li>
              <li><b>Metadata Updates:</b> The app now attempts a quicker reindex before a full catalog repair, often resolving minor issues.</li>
              <li><b>Catalog Repair:</b> Metadata fields now meet IPTC standards, with labels always visible. More updates coming soon.</li>
              <li><b>Improved Stability After Sleep:</b>Better stability when waking devices from sleep for Mylio Photos+ users.</li>
              <li><b>Syncing During Updates:</b> The "Mylio Update Available" dialog no longer pauses syncing.</li>
              <li><b>Image Editing Presets:</b> Custom presets using the Levels command are now stored correctly.</li>
              <li><b>Apple Photos Library Fix:</b> Resolved an issue with incorrectly paired display images from Apple Photos.</li>
              <li><b>Full-Screen Video & Image View:</b> Exiting full-screen video now returns you to grid view. Press Shift+F to toggle full-screen image view while preserving Info panel and grid state.</li>
              <li><b>New Sync Policy "Everything":</b> A new "Everything" Sync Policy keeps a full-resolution copy of your library on drives that aren't always connected. Use the Vault preset for extra protection.</li>
            </ul>
            <a target="_blank" href='https://community.mylio.com/posts/drafts/introducing-mylio-photos-v244-update-four' color='#000'><b>See the Full Release Notes</b></a>
          </Typography>
            <br/><br/>
        </Stack>

      </Stack>

      {/* <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: '60px', marginTop: '50px', borderColor: '#ddd', marginLeft: '0', marginRight: '0' }} />*/}

      
    </Stack>
    

      
      {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2, lg:4 }} sx={{ px: isBig? `13vw` : `0vw` }}>
        <Typography sx={{ fontWeight: '700', marginBottom: '30px' }} color="#000" variant='h4'>
          One-on-One Services
        </Typography>
        <Stack>
          {profileCard}
        </Stack>
        <Stack sx={{ paddingLeft: isBig? `60px` : `0`  }}>
          {serviceDescription}
        </Stack>
      </Stack> */}

      {/* <Stack direction="column" justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw`, margin: '0 auto', paddingBottom: isBig ? '60px' : '0px'  }}>

        <Typography sx={{ mb: 1.5, mt: 10, fontSize: '40px', fontWeight: 'bold' }} color="#000" variant='h3'>
          What can they help you with?
        </Typography>
        
        <Typography sx={{ mb: 5, fontSize: '24px', fontWeight: '700' }} color="#000" variant='h1'>
          Some of the things our Photo Managers specialize in
        </Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} textAlign={'left'}>

          <Stack justifyContent={'center'} spacing={{sm: 1, md:4}} sx={{ paddingLeft: isBig? '0px' : '0px' }}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Catalog scanned media
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Catalog scanned media for easy browsing and searching, integrated with your digital media.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Customized syncing
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'>
            Configure syncing for each device, ensuring you only have the desired content on each one.
            </Typography>


            <Typography  sx={{  fontWeight: '700',  }} color="#000" variant='body1'>
              Multi-layered backups
            </Typography>
            <Typography sx={{ mb: 3, mt: '0px !important'  }} color="#000" variant='body1'> 
              Establish a multi-layered backup process that includes hard drives, NAS, computers, tablets, smartphones, and cloud accounts.
            </Typography>
          </Stack>

          <Stack sx={{ paddingTop: '60px', paddingLeft: isBig ? '60px' : '12px', paddingRight: isBig ? '60px' : '0px' }}>
            <img
              src={imgTwo}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

        </Stack>

        

   
     

      </Stack>  */}

{/* 
      <Stack direction={{ xs: 'column', sm: 'column' }} justifyContent={`center`} sx={{ px: isBig? `13vw` : `0vw` , margin: '0 auto' }}>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing="2" textAlign={'left'} >

          <Stack sx={{ paddingTop: '60px', paddingRight: isBig ? '80px': '0' }}>
            <img
              src={imgThree}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>

          <Stack justifyContent={'center'}>
            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Mixed-media libraries
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Create a seamless system for cataloging and searching across various file types.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Shared accounts
            </Typography>
            <Typography sx={{ mb: 3  }} color="#000" variant='body1'>
            Setup multiple accounts for collaboration with family, friends, or colleagues. Control over what you share.
            </Typography>

            <Typography sx={{ fontWeight: '700' }} color="#000" variant='body1'>
              Small businesses
            </Typography>
            <Typography sx={{ mb: 3 }} color="#000" variant='body1'>
            Businesses that handle a significant amount of media. Typically for creative, marketing, collaboration, or archival purposes.
            </Typography>
          </Stack>

      </Stack> */}

    {/* </Stack> 

      <Stack direction="column" justifyContent={`center`} sx={{  margin: '0 auto', backgroundColor: '#FBFCFE', mb:6, mt:6 }}>
        <Typography sx={{ mb: 1.5, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', px: isBig? `13vw` : `0vw` }} color="#000" variant='h1' >
          What should you expect?
        </Typography>
        
          <Stack sx={{ paddingLeft:isBig? '65px' : '0', py: isBig? '50px' : '0'}}>
            <img
              src={timelineImage}
              alt="placeholder image"
              width={'100%'}
            />
          </Stack>
        

      </Stack>



      <Typography sx={{ mb: 3, mt:5, fontSize: '34px', fontWeight: '700', textAlign: 'left', pl: isBig? `13vw` : `0vw` }} color="#000" variant='h1'>
        Want to learn more before commiting?
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{xs:1, sm:1, lg:2}} textAlign={"left"} sx={{ px: isBig? `10vw` : `0`}}>
        
        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Chat with us          
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }} >
            Connect with a live member of our team through the chat bubble at the bottom of your browser window. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Chat Now</Button>
        </Stack>

        <Stack sx={{ padding: isBig? '50px': '10px' }}>
          <Typography sx={{ mb: 1.5, fontWeight: '700' }} color="#000" variant='h5'>
            Use Mylio Photos today         
          </Typography>
          <Typography variant='body1' color="#000" sx={{ mb:3, mt:.5 }}>
            All yearly subscriptions come with a free 30 minute set up session. See how that works for you before trying out more. 
          </Typography>
          <Button variant="outlined" target='_blank' sx={{ color: "#0E9AF1", borderColor:"#0E9AF1", backgroundColor: "#fff", maxWidth: '200px' }} href="#">Subscribe</Button> 
        </Stack>
      </Stack> */}

    

  </Stack>
  </>
 )
}
export default News